const videoSvgs = {
  cross: `<svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="global-/-video-/-minimised-/-mobile-/-320" transform="translate(-265.000000, -45.000000)" fill="#FFFFFF">
          <g id="global-/-icon-/-lined-/-cross" transform="translate(263.466762, 42.735568)">
              <g id="plus" transform="translate(1.000000, 1.000000)">
                  <rect id="Rectangle" transform="translate(8.000000, 8.000000) rotate(-45.000000) translate(-8.000000, -8.000000) " x="7" y="-1" width="2" height="18" rx="1"></rect>
                  <rect id="Rectangle" transform="translate(8.000000, 8.000000) rotate(-315.000000) translate(-8.000000, -8.000000) " x="7" y="-1" width="2" height="18" rx="1"></rect>
              </g>
          </g>
      </g>
  </g>
  </svg>`,

  minimise: `<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="global-/-icon-/-lined-/-minimise" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <g id="minimize-2" transform="translate(1.500000, 2.000000)" stroke="#FFFFFF" stroke-width="1.66666667">
              <polyline id="Shape" points="0.833333333 9.16666667 5.83333333 9.16666667 5.83333333 14.1666667"></polyline>
              <polyline id="Shape" points="14.1666667 5.83333333 9.16666667 5.83333333 9.16666667 0.833333333"></polyline>
              <path d="M9.16666667,5.83333333 L15,0" id="Shape"></path>
              <path d="M0,15 L5.83333333,9.16666667" id="Shape"></path>
          </g>
      </g>
  </svg>`,

  maximise: `<svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g id="global-/-video-/-minimised-/-mobile-/-320" transform="translate(-188.000000, -43.000000)" stroke="#FFFFFF" stroke-width="1.5">
          <g id="maximize-2" transform="translate(189.466762, 44.235568)">
              <polyline id="Shape" points="10 0 15 0 15 5"></polyline>
              <polyline id="Shape" points="5 15 0 15 0 10"></polyline>
              <path d="M15,0 L9.16666667,5.83333333" id="Shape"></path>
              <path d="M0,15 L5.83333333,9.16666667" id="Shape"></path>
          </g>
      </g>
  </g>
</svg>`,
}

const stickyVideoPlayer = `
<div class="StickyVideo-player">
<div class="StickyVideo-player-menu">
  <button class="StickyVideo-menu-button StickyVideo-player-format">
    <span class="StickyVideo-format-icon StickyVideo-minimise">
    ${videoSvgs.minimise}
    </span>
    <span class="StickyVideo-format-icon StickyVideo-maximise hidden">
    ${videoSvgs.maximise}
    </span>
  </button>
  <button class="StickyVideo-menu-button StickyVideo-close">
    ${videoSvgs.cross}
  </button>
</div>
<div class="StickyVideo-player-youtube">
</div>
</div>
`

export default stickyVideoPlayer
