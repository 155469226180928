const restartAnimation = el => {
  el.classList.add('pause-animation')
  setTimeout(() => el.classList.remove('pause-animation'), 0)
}

export default class LogoSlidingCarouselController {
  constructor() {
    let containers = document.querySelectorAll('.LogoSlidingCarousel-container')
    containers.forEach(container => {
      let childCount = container.children.length
      var totalWidth = 0
      for (var i = 0; i < childCount; i++) {
        totalWidth += container.children[i].offsetWidth
      }
      container.style.width = totalWidth + 'px'
      restartAnimation(container) //this solves Safari problem with animation/transitions
    })
  }
}
