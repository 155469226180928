import template from './template'

const stickyVideo = (function() {
  'use strict'

  let youTubePlayer
  let media
  let player
  let youTubeVideo
  let minimise
  let maximise

  let youTubePlayerApi = {
    play: () => {
      youTubePlayer.playVideo()
    },
    pause: () => {
      youTubePlayer.pauseVideo()
    },
  }

  function loadYouTubeMedia(videoId, onLoad, youTubeVideo) {
    if ('YT' in window) {
      if (youTubePlayer) {
        if (youTubePlayer.getVideoData()['video_id'] !== videoId) {
          youTubePlayer.loadVideoById(videoId)
        }
        window.setTimeout(onLoad.bind(null, youTubePlayerApi), 0)
      }
    } else {
      //2. onYouTubeIframeAPIReady creates an iframe and the youtube player
      //after the iframe script downloads
      window.onYouTubeIframeAPIReady = function createPlayer() {
        //eslint-disable-next-line
        youTubePlayer = new YT.Player(youTubeVideo, {
          videoId: videoId,
          events: {
            onReady: onLoad.bind(null, youTubePlayerApi),
          },
          playerVars: { rel: 0, showinfo: 0, ecver: 2 }, //switch off related videos at the end
        })
      }
      //1. this loads iframe api code asynchronously
      var tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      var firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }
  }

  function initialiseVideoPlayer() {
    let stickyVideo = document.createElement('div')
    stickyVideo.innerHTML = template
    document.body.appendChild(stickyVideo)

    youTubeVideo = stickyVideo.querySelector('.StickyVideo-player-youtube')

    //events
    player = stickyVideo.querySelector('.StickyVideo-player')
    minimise = stickyVideo.querySelector('.StickyVideo-minimise')
    maximise = stickyVideo.querySelector('.StickyVideo-maximise')
    const closeButton = stickyVideo.querySelector('.StickyVideo-close')
    const formatButton = stickyVideo.querySelector('.StickyVideo-player-format')

    closeButton.addEventListener('click', closeVideo)

    formatButton.addEventListener('click', e => {
      e.stopPropagation()
      toggleMinMax()
    })
    window.addEventListener('scroll', minimiseOnce)

    player.addEventListener('click', minimiseOnce)

    document.addEventListener('keydown', function(e) {
      if (e.key === 'Escape') {
        minimiseOnce()
      }
    })
  }

  function playVideo(id) {
    loadYouTubeMedia(
      id,
      function(loadedMedia) {
        media = loadedMedia
        player.classList.add('active')
        media.play()
      },
      youTubeVideo
    )
  }

  function closeVideo() {
    if (media) {
      media.pause()
    }
    player.classList.remove('active', 'StickyVideo-minimised')
    media = null
  }

  function toggleMinMax() {
    player.classList.toggle('StickyVideo-minimised')
    maximise.classList.toggle('hidden')
    minimise.classList.toggle('hidden')
  }

  function minimiseOnce() {
    player.classList.add('StickyVideo-minimised')
    maximise.classList.remove('hidden')
    minimise.classList.add('hidden')
  }

  function play(event) {
    const videoId = event.currentTarget.dataset.youtubeId
    let players = document.querySelectorAll('.StickyVideo-player')

    if (players.length === 0) {
      initialiseVideoPlayer()
    }

    playVideo(videoId)
  }

  return {
    play: play,
  }
})()

export default stickyVideo
