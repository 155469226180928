function onChange (e) {
  const filenameEl = $('.FileInput-filename', e.currentTarget)
  const files = Array.prototype.slice.call(e.target.files)

  let filenameHtml = ''
  if (files.length) {
    files.forEach((file, index) => {
      filenameHtml += file.name
      if (index < files.length - 1) filenameHtml += ', '
    })
  } else {
    filenameHtml = 'No file chosen'
  }

  filenameEl.html(filenameHtml)
}

export default class FileInputController {
  constructor () {
    $('body').on('change', '.FileInput', onChange)
  }
}
