export const footerText = `By clicking submit you consent to us using your name and email address for marketing purposes. See our <a href="https://www.zerodeposit.com/privacy" target="_blank">privacy notice</a> for full details.`

const content = (function() {
  return {
    START: {
      currentPage: 'genericStart',
      backPage: null,
      heading: {
        title: 'To get started, tell us who you are:',
        subtitle:
          'Already a partner agent? <a href="https://app.zerodeposit.com/agents/login/?next=/agents/" target="_blank">Log in to your account</a>',
      },
      arrows: [
        {
          title: 'Landlord',
          id: 'rec_LL_Click',
          class: 'landlordYesNo',
          content: "I'm interested in using Zero Deposit",
        },
        {
          title: 'Tenant',
          id: 'rec_Tenant_Click',
          class: 'subscriberTenant',
          content: 'I want to rent deposit free',
        },
        {
          title: 'Agent',
          id: 'rec_Agent_Click',
          class: 'agent1',
          content: 'I want to become a Zero Deposit partner',
        },
      ],
    },
    SUBSCRIBER_TENANT: {
      //this page leads to tenant hub in zd-web
      currentPage: 'subscriberTenant',
      backPage: 'genericStart',
      heading: {
        title: 'To help with your rental journey, we need your name and email',
        subtitle: 'We will then guide you through the Zero Deposit process',
      },
      formId: 'subscriber_tenant',
      inputs: ['name', 'email'],
      recId: 'rec_subscriber_tenant',
      footerText,
      userType: 'subscriber_tenant',
      buttonClass: 'Button-submit',
      buttonText: 'submit',
    },
    AGENT1: {
      currentPage: 'agent1',
      backPage: 'genericStart',
      heading: {
        title:
          "It's easy to become a Zero Deposit partner today. Just enter a few details.",
      },
      formId: 'agent1',
      inputs: ['name', 'email'],
      recId: 'rec_Agent_Step1',
      footerText: null,
      buttonClass: 'ModalSignUp-button-next special-next',
      buttonText: 'next',
      name: '',
      email: '',
    },
    AGENT2: {
      currentPage: 'agent2',
      backPage: 'agent1',
      heading: { title: 'And now your company details.' },
      formId: 'agent-form',
      recId: 'rec_Submit_Agent',
      footerText: null,
      buttonClass: 'Button-submit',
      buttonText: 'submit',
    },
    LANDLORD_YES_NO: {
      currentPage: 'landlordYesNo',
      backPage: 'genericStart',
      heading: { title: 'Do you manage the deposit yourself?' },
      arrows: [
        {
          title: 'Yes',
          class: 'landlordSelfManage',
          id: 'rec_Direct_Landlord',
          content: 'I collect the deposit from my tenants myself.',
        },
        {
          title: 'No',
          class: 'landlordUsesAgent',
          id: 'rec_Agent_Landlord',
          content: 'I use a letting agent to collect the deposit.',
        },
      ],
    },
    LANDLORD_SELF_MANAGE: {
      currentPage: 'landlordSelfManage',
      backPage: 'landlordYesNo',
      heading: {
        title:
          'We’ll email everything you need to know about renting with Zero Deposit.',
      },
      formId: 'landlord-self-manage',
      inputs: ['name', 'email'],
      recId: 'rec_Submit_Direct_Landlord',
      userType: 'direct_landlord',
      footerText,
      buttonClass: 'Button-submit',
      buttonText: 'submit',
    },
    LANDLORD_USES_AGENT: {
      currentPage: 'landlordUsesAgent',
      backPage: 'landlordYesNo',
      heading: { title: 'Is your agent a Zero Deposit partner?' },
      arrows: [
        {
          title: 'Yes',
          id: 'rec_Landlord_Agent_Is_Partner',
          class: 'partner',
          content: '',
        },
        {
          title: 'No',
          id: 'rec_Landlord_Agent_Not_Partner',
          class: 'notPartner',
          content: '',
        },
        {
          title: 'Not sure',
          id: 'rec_Landlord_Agent_Unknown',
          class: 'notSure',
          content: '',
        },
      ],
    },
    PARTNER: {
      currentPage: 'partner',
      backPage: 'landlordUsesAgent',
      title: 'Simply tell your agent you’re happy to use Zero Deposit.',
      text: 'They’ll take care of everything for you.',
      recId: 'rec_Submit_Landlord_Partner_Agent',
    },
    NOT_PARTNER: {
      currentPage: 'notPartner',
      backPage: 'landlordUsesAgent',
      heading: {
        title:
          "We'll email you everything you need to get started with Zero Deposit",
      },
      formId: 'not-partner',
      inputs: ['name', 'email'],
      unknownPartnerFormId: 'partner-unknown',
      recId: 'rec_Submit_LL_Not_Partner',
      userType: 'not_partner',
      footerText,
      buttonClass: 'Button-submit',
      buttonText: 'submit',
    },
    NOT_SURE: {
      currentPage: 'notSure',
      backPage: 'landlordUsesAgent',
      heading: {
        title:
          "We'll email you everything you need to get started with Zero Deposit",
      },
      formId: 'partner-unknown',
      inputs: ['name', 'email'],
      recId: 'rec_Submit_Partner_Unknown',
      userType: 'unknown_partner',
      footerText,
      buttonClass: 'Button-submit',
      buttonText: 'submit',
    },
    footerText,
    successTitle: 'Check your email',
    successText: "We've sent you everything you need to know",
    agentSuccessTitle: "You're all done",
    agentSuccessText: 'One of our team will be in touch as soon as possible',
    conciergeSuccessTitle: 'We’re working on it',
    conciergeSuccessText:
      'We’ll get in contact with your agent and keep you updated',
  }
})()

export default content
