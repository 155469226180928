const scrollLimit = 5

function getST() {
  return $('body, html').scrollTop() || $(document).scrollTop()
}

//control logo size on window resize and on page load

function removeClassesIfExist(el, classes) {
  classes.forEach(function(c) {
    if (el.hasClass(c)) {
      el.removeClass(c)
    }
  })
}

function displayLogo(el, logoToAdd, logosToRemove) {
  removeClassesIfExist(el, logosToRemove)
  el.addClass(logoToAdd)
}

function setLogo(el, bare, medium) {
  if (window.matchMedia('(max-width: 768px)').matches) {
    displayLogo(el, bare, [medium])
  } else {
    displayLogo(el, medium, [bare])
  }
}

function isHomepage() {
  return /^\/$/.test(window.location.pathname)
}

// logo options

let bare = 'Svg-logo-bare'
let medium = 'Svg-logo-medium'
let bareWhite = 'Svg-logo-white-bare'
let mediumWhite = 'Svg-logo-white-medium'

export default class MainNavController {
  constructor() {
    const mainNav = $('.MainNav')
    const navClone = mainNav.clone()
    const navMobile = $('.MainNav-mobile')
    const hamburger = $('.MainNav-hamburger', mainNav)
    const hamburgerPinned = $('.MainNav-hamburger', navClone)
    const openNavLogo = $('.MainNav-logo', navClone)
    const navLogo = $('.MainNav-logo', mainNav)

    navClone.addClass('MainNav--pinned')

    //scrolled nav bar should always have bareWhite logo
    openNavLogo.addClass(bareWhite)

    $('.MainNav').after(navClone)

    $(window).on('load resize', () => {
      if (isHomepage()) {
        setLogo(navLogo, bareWhite, mediumWhite)
      } else {
        setLogo(navLogo, bare, medium)
      }

      // switch off mobile nav if the screen is resized to over 768px
      if (window.matchMedia('(min-width:768px)').matches) {
        removeClassesIfExist(navMobile, ['MainNav-mobile--open'])
        removeClassesIfExist(hamburger, ['is-active'])
        removeClassesIfExist(hamburgerPinned, ['is-active'])
      }
    })

    $('.MainNav-hamburger').click(() => {
      navMobile.toggleClass('MainNav-mobile--open')
      if (isHomepage()) {
        navLogo.toggleClass(bareWhite).toggleClass(bare)
      }
    })

    $(window).scroll(() => {
      if (navClone.hasClass('MainNav--forced')) return

      const st = getST()
      $('.MainNav--pinned').toggleClass('MainNav--opened', st > scrollLimit)
    })
  }
}
