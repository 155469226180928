import { supportsTouch } from 'utils.js'

const HOVER_DELAY = 200
const template = `<div class="Tooltip-balloon is-closed"><div class="Tooltip-text" /></div>`

export default class TooltipController {
  constructor() {
    $('.Tooltip').each((index, tooltip) => {
      let isOpen = false
      let showTimer
      const inputs = $('input', tooltip)
      if (inputs.length > 0) {
        inputs.focus(() => {
          showTimer = setTimeout(() => {
            this.show(tooltip)
          }, HOVER_DELAY)
        })
        inputs.focusout(() => {
          clearTimeout(showTimer)
          this.hide(tooltip)
        })
      } else {
        if (supportsTouch) {
          $(tooltip).on('tap', () => {
            if (isOpen) this.hide(tooltip)
            else {
              this.show(tooltip)
              $(tooltip).data('bodyClick', () => {
                this.hide(tooltip)
                isOpen = false
                $(document).off('mousedown', $(tooltip).data('bodyClick'))
              })
              setTimeout(() =>
                $(document).on('mousedown', $(tooltip).data('bodyClick'))
              )
            }
            isOpen = !isOpen
          })
        } else {
          $(tooltip).mouseover(() => {
            showTimer = setTimeout(() => {
              this.show(tooltip)
            }, HOVER_DELAY)
          })
          $(tooltip).mouseout(() => {
            clearTimeout(showTimer)
            this.hide(tooltip)
          })
        }
      }
    })
  }

  show(el) {
    const balloon = $(template)
    $('.Tooltip-balloon', el).remove()
    $('.Tooltip-text', balloon).text($(el).data('tooltip'))
    setTimeout(() => {
      balloon.removeClass('is-closed')
    }, 50)
    $(el).append(balloon)
  }

  hide(el) {
    const balloon = $('.Tooltip-balloon', el)
    balloon.addClass('is-closed')
    setTimeout(() => {
      balloon.remove()
    }, 250)
  }
}
