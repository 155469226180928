import ToastController from './ToastController'

export default class Toast {
  constructor({ element, title, message, type = 'message', dismiss = false }) {
    if (!element) {
      const toastTemplate = `
      <div class="Toasts-item is-new u-accel u-clearfix" data-type="${type}" data-dismiss="${dismiss}">
        <strong>${title}</strong> ${message}
        <a href="#" div class="Toasts-item-close"><i class="Icons-close"></i></a>
      </div>
      `
      this.el = $(toastTemplate)

      if (type === 'error') {
        this.el.prepend('<i class="Toasts-item-icon Icons-error"></i>')
      }

      setTimeout(() => {
        this.el.removeClass('is-new')
      }, 50)
    } else {
      this.el = $(element)
    }

    if (this.el.data('dismiss') !== false) {
      setTimeout(() => {
        this.destroy()
      }, Number(this.el.data('dismiss')))
    }

    $('.Toasts-item-close', this.el).on('tap', e => {
      this.destroy()
      e.preventDefault()
    })

    this.el.swipe({
      swipe: (event, direction) => {
        if (direction !== 'none') {
          this.destroy()
          this.el.addClass(`is-swiped-${direction}`)
        }
      },
    })

    ToastController.push(this)
  }

  destroy() {
    this.el.addClass('is-hidden')
    setTimeout(() => {
      this.el.addClass('is-flat')
      setTimeout(() => {
        this.el.remove()
        ToastController.pop(this)
      }, 250)
    }, 250)
  }
}
