import insertionQ from 'insertion-query'

function initialise(el) {
  const sel = $('select', el)

  if (sel[0].selectedIndex !== -1) {
    const currItem = $(':selected', sel)
    $('.Select-value', el).text(currItem.text())
  }

  $(el).toggleClass('has-value', $(sel).val() !== '')

  sel.on('change', e => {
    const currItem = $(':selected', e.currentTarget)
    $('.Select-value', el).text(currItem.text())

    $(el).toggleClass('has-value', $(sel).val() !== '')
  })

  sel.on('mousedown focus', () => {
    $(el).addClass('is-focused')
  })

  sel.on('focusout', () => {
    $(el).removeClass('is-focused')
  })
}

export default class SelectController {
  constructor() {
    $('.Select').each((index, el) => initialise(el))

    // handle new instances being added at runtime
    insertionQ('.Select').every(el => initialise(el))
  }
}
