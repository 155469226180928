const horizontalScroll = (element, direction, speed, distance, step) => {
  let scrollAmount = 0
  const slideTimer = setInterval(() => {
    if (direction == 'left') {
      element.scrollLeft -= step
    } else {
      element.scrollLeft += step
    }

    scrollAmount += step
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer)
    }
  }, speed)
}

export default class BlogPostListController {
  constructor() {
    const BlogCarousels = document.querySelectorAll('.BlogPostCarousel')

    BlogCarousels.forEach(BlogCarousel => {
      const container = BlogCarousel.querySelector(
        '.BlogPostCarousel-container'
      )
      const buttonNext = BlogCarousel.querySelector(
        '.BlogPostCarousel-button-right'
      )
      const buttonBack = BlogCarousel.querySelector(
        '.BlogPostCarousel-button-left'
      )

      buttonNext.addEventListener('click', () => {
        horizontalScroll(container, 'right', 25, 300, 10)
      })

      buttonBack.addEventListener('click', () => {
        horizontalScroll(container, 'left', 25, 300, 10)
      })
    })
  }
}
