const svgSuccess = `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="123px" height="123px" viewBox="0 0 123 123" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
    <title>Group 2</title>
    <desc>Created with Sketch.</desc>
    <g id="Design-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="375" transform="translate(-126.000000, -260.000000)">
            <g id="Group-2" transform="translate(126.000000, 260.000000)">
                <circle id="Oval-3" fill="#F45372" cx="61.5" cy="61.5" r="61.5"></circle>
                <polyline id="Line" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" points="41 62.6086768 54.9688201 77 87 44"></polyline>
            </g>
        </g>
    </g>
</svg>`

const svgFail = `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="123px" height="123px" viewBox="0 0 123 123" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
    <title>Group 4</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="exclamation">
            <g id="Group-4">
                <g id="Group-2-Copy" fill="#F45372">
                    <circle id="Oval-3" cx="61.5" cy="61.5" r="61.5"></circle>
                </g>
                <polyline id="Path-3" stroke="#FFFFFF" stroke-width="6" stroke-linecap="round" points="61.5 30 61.5 46.080558 61.5 66.5580589 61.5 67.349617 61.5 67.7074447 61.5 68.4176783 61.5 68.775506 61.5 69.1333337 61.5 69.4640532 61.5 69.7839294 61.5 69.4586315 61.5 69.127912 61.5 68.8026142"></polyline>
                <circle id="Oval" stroke="#FFFFFF" fill="#FFFFFF" cx="62" cy="83" r="5"></circle>
            </g>
        </g>
    </g>
    </svg>
`

const svgClose = `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="ModalSignUp-close">
    <g id="Design-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="860" transform="translate(-774.000000, -54.000000)" fill-rule="nonzero" stroke="#9D9D9D" stroke-width="2">
            <g id="Quit" transform="translate(764.000000, 45.000000)">
                <g id="X">
                    <g id="Group" transform="translate(22.876116, 21.920310) rotate(45.000000) translate(-22.876116, -21.920310) translate(7.376116, 6.420310)">
                        <path class="ModalSignUp-close" d="M15.46875,0 L15.46875,30.9375" id="Line-8"></path>
                        <path class="ModalSignUp-close" d="M30.9375,15.46875 L-2.13162821e-14,15.46875" id="Line-8"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`

const BackArrow = page => `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="25px" viewBox="0 0 14 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="${page}-back ModalSignUp-back">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <g id="Design-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="860" transform="translate(-60.000000, -53.000000)" fill-rule="nonzero" stroke="#9D9D9D" stroke-width="2">
            <g id="&lt;" transform="translate(59.000000, 53.000000)">
                <g id="X">
                    <g id="Group" transform="translate(13.129164, 12.566757) rotate(45.000000) translate(-13.129164, -12.566757) translate(4.629164, 3.566757)">
                        <path class="${page}-back ModalSignUp-back" d="M1.46875,1 L1.46875,16.46875" id="Line-8"></path>
                        <path class="${page}-back ModalSignUp-back" d="M16.9375,16.46875 L1.46875,16.46875" id="Line-8"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`

const RightArrow = `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
    <title>Group 2</title>
    <desc>Created with Sketch.</desc>
    <g id="Design-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="860" transform="translate(-638.000000, -331.000000)" fill="#F45372">
            <g id="Group-2" transform="translate(638.000000, 331.000000)">
                <g>
                    <g id="&gt;" transform="translate(14.182338, 10.182338) rotate(45.000000) translate(-14.182338, -10.182338) translate(6.982338, 2.982338)" fill-rule="nonzero">
                        <path  d="M0.800000012,0 L13.2000002,0 C13.8627419,-1.21743677e-16 14.4000002,0.537258308 14.4000002,1.20000002 L14.4000002,1.20000002 C14.4000002,1.86274173 13.8627419,2.40000004 13.2000002,2.40000004 L0.800000012,2.40000004 C0.358172205,2.40000004 5.41083009e-17,2.04182783 0,1.60000002 L0,0.800000012 C-5.41083009e-17,0.358172205 0.358172205,8.11624513e-17 0.800000012,0 Z" id="Rectangle" transform="translate(7.200000, 1.200000) scale(-1, 1) translate(-7.200000, -1.200000) "></path>
                        <path  d="M12.8000002,0 L13.6000002,0 C14.041828,-8.11624513e-17 14.4000002,0.358172205 14.4000002,0.800000012 L14.4000002,13.2000002 C14.4000002,13.8627419 13.8627419,14.4000002 13.2000002,14.4000002 L13.2000002,14.4000002 C12.5372585,14.4000002 12.0000002,13.8627419 12.0000002,13.2000002 L12.0000002,0.800000012 C12.0000002,0.358172205 12.3581724,8.11624513e-17 12.8000002,0 Z" id="Rectangle-Copy" transform="translate(13.200000, 7.200000) scale(-1, 1) translate(-13.200000, -7.200000) "></path>
                    </g>
                    <path d="M0.800000012,8.80000013 L21.2000003,8.80000013 C21.862742,8.80000013 22.4000003,9.33725844 22.4000003,10.0000001 L22.4000003,10.0000001 C22.4000003,10.6627419 21.862742,11.2000002 21.2000003,11.2000002 L0.800000012,11.2000002 C0.358172205,11.2000002 5.41083009e-17,10.841828 0,10.4000002 L0,9.60000014 C-5.41083009e-17,9.15817234 0.358172205,8.80000013 0.800000012,8.80000013 Z" id="Rectangle" fill-rule="nonzero" transform="translate(11.200000, 10.000000) scale(-1, 1) translate(-11.200000, -10.000000) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`

module.exports = {
  svgSuccess,
  svgFail,
  BackArrow,
  svgClose,
  RightArrow,
}
