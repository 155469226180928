export default class RadioController {
  constructor() {
    const radioGroups = document.querySelectorAll('.RadioGroup.is-conditional')
    radioGroups.forEach(radioGroup => {
      const $textWell = $('.TextWell', radioGroup)
      const $radio = $('input', radioGroup)
      const $selectedRadio = $('input:checked', radioGroup)
      $textWell
        .toggleClass(
          'TextWell--hidden',
          // eslint-disable-next-line
          !Boolean($selectedRadio.data('showtext'))
        )
        .text($selectedRadio.data('showtext'))

      $radio.click(e => {
        $textWell
          .toggleClass(
            'TextWell--hidden',
            // eslint-disable-next-line
            !Boolean($(e.target).data('showtext'))
          )
          .text($(e.target).data('showtext'))
      })
    })

    const errorGroups = document.querySelectorAll('.RadioGroup.is-error')
    errorGroups.forEach(errorGroup => {
      $('input', errorGroup).click(() => {
        errorGroup.classList.remove('is-error')
      })
    })
  }
}
