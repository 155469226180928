import insertionQ from 'insertion-query'

function initialise(el) {
  $(el).on('tap', e => {
    const reveal = $(e.currentTarget)
    reveal.toggleClass('is-open')
    if (reveal.is('.is-open')) {
      $('.TextReveal-content-wrap', reveal).css({
        maxHeight: $('.TextReveal-content', reveal).outerHeight(),
      })
    } else {
      $('.TextReveal-content-wrap', reveal).css({
        maxHeight: 0,
      })
    }
  })
}

export default class TextRevealController {
  constructor() {
    $('.TextReveal').each((index, el) => initialise(el))

    // handle new instances being added at runtime
    insertionQ('.TextReveal').every(el => initialise(el))
  }
}
