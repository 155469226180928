const tagsArgs = /^[^<||>%*^{}\\~\[\]@]+$/i

export const showErrors = (input, inputHelpers, errorMessage) => {
  input.classList.add('is-error')
  inputHelpers.setAttribute('data-error', errorMessage)
}

export const removeErrors = input => {
  input.classList.remove('is-error')
}

const textInputIsValid = value => {
  if (!value || !tagsArgs.test(value)) {
    return false
  } else {
    return true
  }
}

const validateForm = () => {
  const emailRegex = /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/

  let nameValue
  let emailValue

  if (document.forms['Form']['name']) {
    nameValue = document.forms['Form']['name'].value
  }

  if (document.forms['Form']['emailAddress']) {
    emailValue = document.forms['Form']['emailAddress'].value
  }

  let nameInput = document.querySelector('.Inputname')
  let emailInput = document.querySelector('.InputemailAddress')
  let companyInput = document.querySelector('.InputcompanyName')
  let letsInput = document.querySelector('.RadioGroup')

  const emailIsValid = value => {
    if (!value || !emailRegex.test(emailValue)) {
      return false
    } else {
      return true
    }
  }

  const radioInputIsValid = value => {
    if (!value) {
      return false
    } else {
      return true
    }
  }

  const getValueFromRadioButton = name => {
    let buttons = document.getElementsByName(name)
    for (var i = 0; i < buttons.length; i++) {
      let button = buttons[i]
      if (button.checked) {
        return button.value
      }
    }
    return null
  }

  if (nameInput && emailInput) {
    let nameHelpers = nameInput.childNodes[3]
    let emailHelpers = emailInput.childNodes[3]

    if (!textInputIsValid(nameValue)) {
      showErrors(nameInput, nameHelpers, 'a valid name is required')
    } else {
      removeErrors(nameInput)
    }

    if (!emailIsValid(emailValue)) {
      showErrors(emailInput, emailHelpers, 'a valid email is required')
    } else {
      removeErrors(emailInput)
    }

    if (textInputIsValid(nameValue) && emailIsValid(emailValue)) {
      return true
    } else {
      return false
    }
  }

  if (companyInput && letsInput) {
    let company = document.forms['Form']['companyName']
    let companyValue = company.value
    let letsValue = getValueFromRadioButton('lets')
    // let companyInput = document.querySelector('.InputcompanyName')
    let companyHelpers = companyInput.childNodes[3]
    // let letsInput = document.querySelector('.RadioGroup')
    let letsHelpers = letsInput.childNodes[3]

    if (!textInputIsValid(companyValue)) {
      showErrors(
        companyInput,
        companyHelpers,
        'a valid company name is required'
      )
    } else {
      removeErrors(companyInput)
    }

    if (!radioInputIsValid(letsValue)) {
      showErrors(letsInput, letsHelpers, 'this field is required')
    } else {
      removeErrors(letsInput)
    }

    if (textInputIsValid(companyValue) && radioInputIsValid(letsValue)) {
      return true
    } else {
      return false
    }
  }
}

export { validateForm }
