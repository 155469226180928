export default class SocialShareController {
  constructor() {
    const socialShare = document.querySelectorAll('.SocialShare')
    const socialShareButtons = document.querySelectorAll('.SocialShare-social')
    const clipboardCopy = document.querySelectorAll('.SocialShare-copy')
    const tooltipText = document.querySelectorAll('.SocialShare-tooltip-text')

    //expand social share 'button'
    socialShare.forEach(socialShareEl => {
      const text = socialShareEl.querySelector('.SocialShare-text')
      const shareIcon = socialShareEl.querySelector('.SocialShare-icon-share')

      //expand social share group
      socialShareEl.addEventListener('click', () => {
        socialShareEl.classList.add('is-open')
        text.classList.add('hidden')
      })

      //collapse on share icon click if open
      shareIcon.addEventListener('click', e => {
        if (socialShareEl.classList.contains('is-open')) {
          e.stopPropagation()
        }
        socialShareEl.classList.remove('is-open')
        text.classList.remove('hidden')
      })
    })

    clipboardCopy.forEach(copyButton => {
      copyButton.addEventListener('click', () => {
        this.copyToClipboard(this.linkToCopy(copyButton))
        this.updateInnerText(tooltipText, 'Link copied')
      })

      copyButton.addEventListener('mouseout', () => {
        this.updateInnerText(tooltipText, 'Copy link')
      })
    })

    socialShareButtons.forEach(socialShareEl => {
      socialShareEl.addEventListener('click', () => {
        this.openPopup(socialShareEl)
      })
    })
  }

  linkToCopy(element) {
    let linkToCopy
    if (element.hasAttribute('data-clipboard-link')) {
      linkToCopy = element.getAttribute('data-clipboard-link')
    } else {
      linkToCopy = window.location.href
    }
    return linkToCopy
  }

  updateInnerText(element, text) {
    element.forEach(el => {
      el.innerHTML = text
    })
  }

  copyToClipboard(value) {
    //create and distroy a temporary selectable element
    const temporaryInput = document.createElement('input')
    document.body.appendChild(temporaryInput)
    temporaryInput.value = value
    temporaryInput.select()
    document.execCommand('copy')
    document.body.removeChild(temporaryInput)
  }

  openPopup(el) {
    //gets the url from data-href attribute
    const url = el.dataset.href

    const width = 600
    const height = 500
    const left = screen.width / 2 - width / 2
    const top = screen.height / 2 - height / 2

    // eslint-disable-next-line
    let popup = window.open(
      url,
      'pop',
      `width=${width}, height=${height}, left=${left}, top=${top}`
    )
  }
}
