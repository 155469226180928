function initialise(el) {
  const id = $(el).data('ytid')
  $('.Video-contents', el).click(() => {
    const embed = $(`
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/${id}?rel=0&showinfo=0&autoplay=1&modestbranding=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    `)
    $('.Video-container', el)
      .empty()
      .append(embed)
  })
}

export default class VideoController {
  constructor() {
    $('.Video').each((index, el) => initialise(el))
  }
}
