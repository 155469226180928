const toasts = []

export default class ToastController {
  static get toasts () {
    return toasts
  }

  static push (toast) {
    toasts.push(toast)
    $('.Toasts-items').append(toast.el)
  }

  static pop (toast) {
    const ind = toasts.indexOf(toast)
    if (ind !== -1) {
      toasts.splice(ind, 1)
    }
  }

  constructor () {
    // If there aren't any toasts, create a container for later use
    if ($('body > .Toasts').length === 0) {
      $('body').append($('<div class="Toasts"><div class="Toasts-items" /></div>'))
    } else {
      $('body > .Toasts .Toasts-item').each((index, element) => {
        return new zd.Toast({ element })
      })
    }
  }
}
