import insertionQ from 'insertion-query'

function initialise(el) {
  const stepper = $(el)
  const field = $('.NumberStepper-value', stepper)

  $('.NumberStepper-dn', stepper).on('tap', () => {
    let nextVal = Number(field.val()) - 1
    let min = field.attr('min') && Number(field.attr('min'))
    if (min && nextVal < min) {
      nextVal = min
    }
    field.val(nextVal)
  })

  $('.NumberStepper-up', stepper).on('tap', () => {
    let nextVal = Number(field.val()) + 1
    let max = field.attr('max') && Number(field.attr('max'))
    if (max && nextVal > max) {
      nextVal = max
    }
    field.val(nextVal)
  })
}

export default class NumberStepperController {
  constructor() {
    $('.NumberStepper').each((index, el) => initialise(el))

    // handle new instances being added at runtime
    insertionQ('.NumberStepper').every(el => initialise(el))
  }
}
