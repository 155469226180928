export default class Modal {
  constructor({
    message,
    title = null,
    type = 'alert',
    onConfirm = null,
    onCancel = null,
  }) {
    $('body .Modal').remove()
    $('body .PageContents')
    this.el = $(`<div class="Modal is-opening">
    <div class="Modal-ui">
      <div class="Modal-title"> </div>
      <div class="Modal-body"></div>
      <div class="Modal-controls"></div>
    </div>
    </div>
    `)
    this.type = type
    this.onCancel = onCancel
    this.onConfirm = onConfirm

    const titleEl = $('.Modal-title', this.el)
    const bodyEl = $('.Modal-body', this.el)
    const controlsEl = $('.Modal-controls', this.el)

    if (title) {
      titleEl.text(title)
    } else {
      titleEl.remove()
    }

    bodyEl.text(message)

    if (type === 'confirm') {
      const cancelButton = $(
        '<button class="Button Button--small">Cancel</button>'
      )
      cancelButton.on('tap', () => {
        if (onCancel) onCancel()
        this.remove()
      })
      controlsEl.append(cancelButton)
    }

    const confirmButton = $(
      '<button class="Button Button--primary Button--small">OK</button>'
    )
    confirmButton.on('tap', () => {
      if (onConfirm) onConfirm()
      this.remove()
    })

    controlsEl.append(confirmButton)

    $('body').append(this.el)
    setTimeout(() => {
      this.el.removeClass('is-opening')
    }, 50)

    this.el.on('tap', e => {
      if (e.target === this.el[0]) {
        if (type === 'alert' && onConfirm) onConfirm()
        else if (onCancel) onCancel()
        this.remove()
      }
    })

    this.keypressHandlerBound = e => this.keypressHandler(e)
    $('body').on('keydown', this.keypressHandlerBound)
  }

  keypressHandler(e) {
    switch (e.keyCode) {
      case 27: // Escape
        if (this.type === 'alert' && this.onConfirm) this.onConfirm()
        else if (this.onCancel) this.onCancel()
        this.remove()
        e.preventDefault()
        break

      case 13: // Enter
        if (this.type === 'alert' || this.type === 'confirm') {
          if (this.onConfirm) this.onConfirm()
          this.remove()
          e.preventDefault()
          break
        }
    }
  }

  remove() {
    $('body .PageContents')
    $('body').off('keydown', this.keypressHandlerBound)
    this.el.addClass('is-closing')
    setTimeout(() => {
      this.el.remove()
    }, 250)
  }
}
