import content from './content'
import { validateForm } from './validation'
import utils from './utils'

const ModalSignUp = (function() {
  const { createView, pages, getUTMs, hasClass, insertLinkedInPixel } = utils

  let state = {
    extras: {},
  }

  function open(subscriptionUrl, tenantSubscribeUrl) {
    function updateState(obj) {
      if (Object.keys(obj).includes('extras')) {
        state.extras = { ...state.extras, ...obj.extras }
      } else {
        state = { ...state, ...obj }
      }
    }

    function clearState() {
      state = {}
    }

    let modal = new zd.Modal({ type: 'form' })
    let $modal = modal.el
    $modal
      .find('.Modal-ui')
      .empty()
      .removeClass('Modal-ui')
      .addClass('ModalSignUp')

    let modalPage = $('.ModalSignUp')
    createView(modalPage, pages.genericStart, 'start')

    //navigation
    modalPage.on('click', function(e) {
      let elClass = e.target.className

      //get the class on svgs:
      if (elClass.animVal) elClass = elClass.animVal

      //forward flow
      let pageNames = Object.keys(pages)
      pageNames.forEach(pageName => {
        if (hasClass(elClass, pageName)) {
          createView(modalPage, pages[pageName], pageName)
        }
      })

      //backButton flow
      let contentItems = Object.keys(content).map(item => content[item])
      contentItems.forEach(item => {
        if (hasClass(elClass, item.backPage)) {
          createView(modalPage, pages[item.backPage], item.backPage)
        }
      })

      if (elClass.indexOf('genericStart') !== -1) {
        clearState()
      }

      //exit icon
      if (elClass.indexOf('ModalSignUp-close') !== -1) {
        clearState()
        modal.remove()
      }

      //agent1 special flow
      if (elClass.indexOf('agent1') !== -1) {
        createView(modalPage, pages['agent1'], 'agent1')
        let agentName = document.forms['Form']['name']
        let agentEmail = document.forms['Form']['emailAddress']
        agentName.addEventListener('change', () => {
          updateState({ name: agentName.value })
        })
        agentEmail.addEventListener('change', () => {
          updateState({ emailAddress: agentEmail.value })
        })
        //click on next button should validate and create next view
        let nextButton = document.querySelector('.special-next')
        nextButton.addEventListener('click', e => {
          e.preventDefault()
          if (validateForm()) {
            setTimeout(() => {
              createView(modalPage, pages.agent2, 'agent2')
            }, 700)
          }
        })
      }

      //submit form
      if (elClass.indexOf('Button-submit') !== -1) {
        e.preventDefault()
        let form = $('form')
        let data = form.serializeArray()

        let formobj = data.reduce((acc, d) => {
          acc[d.name] = d.value
          return acc
        }, {})

        let extras = {}

        //for agents 'lets' and 'companyName' should be in extras
        if (formobj.lets) {
          extras.lets = formobj.lets
        }

        if (formobj.companyName) {
          extras.companyName = formobj.companyName
        }

        extras = { ...extras, ...state.extras }

        let payload = {
          name: formobj.name || state.name,
          emailAddress: formobj.emailAddress || state.emailAddress,
          userType: formobj.userType,
          phoneNumber: formobj.phoneNumber || '',
          addressLine1: formobj.addressLine1 || state.propertyAddress || '',
          extras: { ...getUTMs(), ...extras },
        }

        let gaStringSuccessPage = 'successPage'

        let subscriberTenantFlow =
          formobj.userType == content.SUBSCRIBER_TENANT.userType
        let agentFlow = formobj.userType == 'agent'

        if (validateForm()) {
          if (subscriberTenantFlow) {
            $modal.addClass('ModalSignUp-expand')
          }
          createView(modalPage, pages.spinnerPage, 'spinnerPage')
          setTimeout(() => {
            $.ajax({
              type: 'POST',
              url: subscriberTenantFlow ? tenantSubscribeUrl : subscriptionUrl,
              data: JSON.stringify(payload),
              contentType: 'application/json',
              success: response => {
                if (agentFlow) {
                  if ('fbq' in window) {
                    // eslint-disable-next-line
                    fbq('track', 'CompleteRegistration')
                  }
                  createView(
                    modalPage,
                    pages.agentSuccessPage,
                    'agentSuccessPage'
                  )
                  insertLinkedInPixel()
                } else if (subscriberTenantFlow) {
                  window.location.href = response.url
                  if ('fbq' in window) {
                    // eslint-disable-next-line
                    fbq('track', 'CompleteRegistration')
                  }
                } else {
                  if ('fbq' in window) {
                    // eslint-disable-next-line
                    fbq('track', 'CompleteRegistration')
                  }
                  createView(modalPage, pages.successPage, gaStringSuccessPage)
                  insertLinkedInPixel()
                }
                clearState()
              },
              error: () => {
                createView(modalPage, pages.failPage, 'failPage')
              },
            })
          }, 800)
        }
      }
    })
  }
  return {
    open: open,
  }
})()

export default ModalSignUp
