import dateformat from 'dateformat'
import { supportsTouch } from 'utils.js'

export default class InputDatePicker {
  constructor(element) {
    if ($(element).is('[id*="__prefix__"]')) return

    let $el = $(element)
    if (!/input/i.test($el[0].nodeName)) $el = $el.find('input')
    // if no touch support, or no input[type=date] support, add pikaday
    if (!supportsTouch || $el.prop('type') !== 'date') {
      $el
        .attr('type', 'text')
        .hide()
        .show()

      const hiddenInput = $el.clone()
      hiddenInput.off('change').attr('type', 'hidden')
      const ignoreName = `__IGNORE__${$el.attr('name')}`

      $el
        .removeAttr('name')
        .attr('id', ignoreName)
        .attr('pattern', '[0-9]{2}/[0-9]{2}/[0-9]{4}')

      if ($el.val() && !$el.parents('.Input').is('.Input--datepicker')) {
        $el.val(
          $el
            .val()
            .split('-')
            .reverse()
            .join('/')
        )
      }

      $('.Input-label', $el.parents('.Input')).attr('for', ignoreName)

      $el.before(hiddenInput)
      // eslint-disable-next-line
      let picker
      if ($el.parents('.Input').is('.Input--datepicker')) {
        import(/* webpackChunkName: "InputDatePikaday" */
        './InputDatePikadayExternal.js').then(
          ({ default: InputDatePikadayExternal }) => {
            picker = new InputDatePikadayExternal($el)
          }
        )
      }

      $el.keyup(e => {
        switch (e.keyCode) {
          case 8: // Backspace
          case 46: // Delete
            return
        }

        let val = $el
          .val()
          .replace(/([- ]|\/{2,})/g, '/') // Convert to slashes
          .replace(/(^\/|[^\d\/])/g, '') // Force digits and slashes only

        switch (true) {
          case /^\d{2}(\/\d{2})?$/.test(val):
            val += '/'
            break

          case /^(\d{3,}|\d{2}\/\d{3,})$/.test(val): {
            const pos = val.indexOf('/') === -1 ? 2 : 5
            val = `${val.slice(0, pos)}/${val.slice(pos)}`
            break
          }
          case val.length > 10:
            val = val.slice(0, 10)
            break
        }

        $el.val(val)
      })

      $el.on('change blur', e => {
        if ($el.val() === '') return

        let ds = $(e.currentTarget)
          .val()
          .split(/[ \/-]/)
        if (ds[0].length !== 4) {
          ds.reverse()
        }

        ds = ds.map((v, i) => {
          if (i === 0 && v.length === 2) {
            return v < 30 ? '20' + v : '19' + v
          } else {
            let s = v + ''
            while (s.length < 2) s = '0' + s
            return s
          }
        })

        try {
          hiddenInput.val(dateformat(ds.join('-'), 'isoDate'))
        } catch (e) {
          /* disappear the error... */
        }

        if (!e.originalEvent.firedBy) {
          $el.val(
            ds
              .slice()
              .reverse()
              .join('/')
          )
        }
      })
    }
  }
}
