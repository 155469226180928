export default class StickyHeaderController {
  constructor() {
    const StickyHeader = document.querySelector('.StickyHeader')
    if (StickyHeader) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 0) {
          StickyHeader.classList.add('StickyHeader-scrolled')
        } else {
          StickyHeader.classList.remove('StickyHeader-scrolled')
        }
      })
    }
  }
}
