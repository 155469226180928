import InputDatePicker from './InputDatePicker'
import insertionQ from 'insertion-query'

const placeholderShown = function(index, el) {
  const _this = el || this
  // eslint-disable-next-line
  if (!!$(_this).val()) {
    $(_this)
      .siblings('.Input-label')
      .css({
        opacity: 1,
        transform: 'translateY(0px)',
      })
  } else {
    $(_this)
      .siblings('.Input-label')
      .removeAttr('style')
  }
}

export default class InputController {
  constructor() {
    const query =
      '.Input:not(.Input--dateentry):not(.Input-dateentry-field) input[type="date"]'
    $(query).each((index, el) => new InputDatePicker(el))

    // handle new instances being added at runtime
    insertionQ(query).every(el => new InputDatePicker(el))

    // focus Input components wherever you click on the element
    $('body').on('click', '.Input:not(.SelectAutocomplete)', e => {
      $('input', e.currentTarget).focus()
    })

    $('body').on('focus', '.Input .Input-input', e => {
      $(e.currentTarget)
        .parents('.Input')
        .addClass('is-focused')
    })

    $('body').on('focusout', '.Input .Input-input', e => {
      $(e.currentTarget)
        .parents('.Input')
        .removeClass('is-focused')
    })

    if (document.documentMode || /edge/i.test(navigator.userAgent)) {
      $('body').on(
        'focus change keyup blur',
        '.Input .Input-input[placeholder]',
        placeholderShown
      )

      $('.Input .Input-input[placeholder]').each(placeholderShown)
    }
  }
}
