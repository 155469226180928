import insertionQ from 'insertion-query'

function initialise(el) {
  $('.NavigationList-button', el).on('click', () => {
    const items = $('.NavigationList-items', el)
    if (items.not('.is-open').length) {
      const bodyClick = e => {
        if (items.has(e.target).length) return

        $('body').off('click', bodyClick)
        items.removeClass('is-open')
      }
      setTimeout(() => {
        $('body').on('click', bodyClick)
      })
      items.addClass('is-open')
    }
  })
}

export default class NavigationListController {
  constructor() {
    $('.NavigationList').each((index, el) => initialise(el))

    // handle new instances being added at runtime
    insertionQ('.NavigationList').every(el => initialise(el))
  }
}
