import DateEntry from './DateEntry'
import insertionQ from 'insertion-query'

export default class DateEntryController {
  constructor() {
    $('.Input.Input--dateentry').each((index, el) => new DateEntry(el))

    // handle new instances being added at runtime
    insertionQ('.Input.Input--dateentry').every(el => new DateEntry(el))
  }
}
