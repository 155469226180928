export default class PcaPredict {
  constructor (element) {
    /*
    Use webpack code-splitting to only load the Date Picker code
    if necessary.

    see https://webpack.js.org/api/module-methods
    */
    import(
      /* webpackChunkName: "PcaPredict" */
      './PcaPredictExternal.js')
    .then(({ default: PcaPredictExternal }) => {
      return new PcaPredictExternal(element)
    })
  }
}
