import autosize from 'autosize'
import insertionQ from 'insertion-query'

const placeholderShown = function(index, el) {
  const _this = el || this
  // eslint-disable-next-line
  if (!!$(_this).val()) {
    $(_this)
      .siblings('.TextArea-label')
      .css({
        opacity: 1,
        transform: 'translateY(0px)',
      })
  } else {
    $(_this)
      .siblings('.TextArea-label')
      .removeAttr('style')
  }
}

function initialise(el) {
  const container = $(el)
  const textinput = $('.TextArea-textarea', el)
  autosize(textinput)

  if (container.data('max-lines')) {
    textinput.css({
      maxHeight: $(el).data('max-lines') * 22,
    })
  }

  if (document.documentMode || /edge/i.test(navigator.userAgent)) {
    $('body').on(
      'focus change keyup blur',
      '.TextArea .TextArea-textarea',
      placeholderShown
    )

    $('.TextArea .TextArea-textarea').each(placeholderShown)
  }
}

export default class TextAreaController {
  constructor() {
    setTimeout(() => {
      $('.TextArea').each((index, el) => initialise(el))
    }, 50)

    // handle new instances being added at runtime
    insertionQ('.TextArea').every(el => initialise(el))
  }
}
