// Polyfills
import 'es6-promise/auto'
import 'core-js'

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

import ButtonController from 'common/Button/ButtonController'
import DateEntry from 'common/DateEntry/DateEntryController'
import FileInputController from 'common/FileInput/FileInputController'
import InputController from 'common/Input/InputController'
import MainNavController from 'marketing/MainNav/MainNavController'
import ModalSignUp from 'marketing/ModalSignUp/ModalSignup'
import NavigationListController from 'common/NavigationList/NavigationListController'
import NumberStepperController from 'common/NumberStepper/NumberStepperController'
import PcaPredictController from 'common/PcaPredict/PcaPredictController'
import RadioController from 'common/Radio/RadioController'
import SelectController from 'common/Select/SelectController'
import TextAreaController from 'common/TextArea/TextAreaController'
import TextRevealController from 'common/TextReveal/TextRevealController'
import ToastController from 'common/Toasts/ToastController'
import TooltipController from 'common/Tooltip/TooltipController'
import VideoController from 'common/Video/VideoController'
import Hamburgers from 'common/Hamburgers/hamburgers'
import LogoSlidingCarouselController from 'marketing/LogoSlidingCarousel/LogoSlidingCarouselController'
import SocialShare from 'marketing/SocialShare/SocialShareController'
import BlogPostCarousel from 'marketing/BlogPostCarousel/BlogPostCarouselController'
import StickyVideo from 'marketing/StickyVideo/StickyVideoController'
import StickyHeaderController from 'common/StickyHeader/StickyHeaderController'

import InputDatePicker from 'common/Input/InputDatePicker'
import Modal from 'common/Modal/Modal'
import PcaPredict from 'common/PcaPredict/PcaPredict'
import Toast from 'common/Toasts/Toast'

import { supportsTouch } from 'utils.js'
import SubNavController from '../components/marketing/SubNav/SubNavController'

$(document).ready(() => {
  $('html').toggleClass('touch', supportsTouch)
  // global class access
  window.zd = {
    ...window.zd,
    InputDatePicker,
    Modal,
    ModalSignUp,
    PcaPredict,
    Toast,
    StickyVideo,
  }

  /* eslint-disable no-new */

  new ButtonController()
  new DateEntry()
  new FileInputController()
  new InputController()
  new MainNavController()
  new NavigationListController()
  new NumberStepperController()
  new PcaPredictController()
  new RadioController()
  new SelectController()
  new TextAreaController()
  new TextRevealController()
  new ToastController()
  new TooltipController()
  new VideoController()
  new Hamburgers()
  new LogoSlidingCarouselController()
  new SocialShare()
  new BlogPostCarousel()
  new SubNavController()
  new StickyHeaderController()
  /* eslint-enable no-new */
})
