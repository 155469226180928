import insertionQ from 'insertion-query'

function initialise(el) {
  let timerId

  $(el).on('tap', e => {
    const button = $(el)
    if ($('.Button-ink', button).length === 0) {
      button.prepend('<div class="Button-ink" />')
    }

    const ink = $('.Button-ink', button)
    ink.removeClass('animate')

    const diameter = Math.max(button.outerWidth(), button.outerHeight())
    ink.css({ height: diameter, width: diameter })

    const buttonOffset = button.offset()
    const xPos = e.pageX - buttonOffset.left - ink.width() * 0.5
    const yPos = e.pageY - buttonOffset.top - ink.height() * 0.5

    ink
      .css({
        top: yPos + 'px',
        left: xPos + 'px',
      })
      .addClass('animate')

    clearTimeout(timerId)
    timerId = setTimeout(() => ink.remove(), 800)
  })
}

export default class ButtonController {
  constructor() {
    $(
      '.Button:not(.Button--link), .FileInput-button, .ToolHeaderSticky-button'
    ).each((index, el) => initialise(el))

    // handle new instances being added at runtime
    insertionQ(
      '.Button:not(.Button--link), .FileInput-button, .ToolHeaderSticky-button'
    ).every(el => initialise(el))
  }
}
