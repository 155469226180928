import { svgSuccess, svgFail, BackArrow, RightArrow, svgClose } from './svgs'

import content from './content'

const utils = (function() {
  return {
    arrowCard: (title, id, classes, content) => {
      return `<div id="${id}" class="ArrowCard ${classes}">
      <div class="ArrowCard-textContainer">
        <span><b>${title}</b></span><br/>
        <span class="ArrowCard-text">${content}</span>
      </div>
      <button>${RightArrow}</button>
      </div>`
    },

    Input: (name, type, placeholder, classes) => `
    <div class="Input Input${name} ModalSignUp-Input ${
      classes ? classes : ''
    }"">
      <div class="Input-field">
        <input class="Input-input id="ModalSignUp-Input-input" type="${type}" name="${name}" placeholder="${placeholder}">
        <label class="Input-label" id="ModalSignUp-Input-label" for="${name}">${placeholder}</label>
        <div class="Input-border" id="ModalSignUp-Input-border"></div>
      </div>
      <div class="Input-helpers" data-error="" data-hint=""></div>
    </div>
  `,

    Radio: (name, id, value, label, checked = false) => `
    <div class="Radio">
      <input class="Radio-input ModalSignUp-Radio-input" type="radio" name="${name}" id="${id}" value="${value}" ${
      checked ? 'checked' : ''
    } data-showtext="" />
      <label class="Radio-label" id="ModalSignUp-Radio-label" for="${id}">${label}</label>
    </div>
  `,

    RadioGroup: (radios, label) => `
    <div class="ModalSignUp-radioWrap">
      <p style="color: #9d9d9d; margin-top:0; font-size: 18px;">${label}</p>
      <div class="RadioGroup">
        <div class="RadioGroup-controls">
          ${radios}
        </div>
      <div class="RadioGroup-helpers" data-error="" data-hint=""></div>
      </div>
    </div>
  `,

    agent2radios: () => `
    ${utils.Radio('lets', 'letsUnder40', '21 - 40', '1-40')}
    ${utils.Radio('lets', 'letsOver40', '41 - 100', '41+')}
  `,

    agent2Inputs: () => `
    <div class="ModalSignUp-InputGroup">
      ${utils.Input('companyName', 'text', 'Company name')}
      ${utils.Input('phoneNumber', 'number', 'Phone number (optional)')}
      ${utils.RadioGroup(utils.agent2radios(), 'Number of lets per month')}
      <input style="display:none;" type="text" name="userType" value="agent"/>
    </div>
  `,

    inputTypes: {
      name: () => utils.Input('name', 'text', 'Name'),
      email: () => utils.Input('emailAddress', 'email', 'Email'),
      phone: () =>
        utils.Input('phoneNumber', 'number', 'Phone number (optional)'),
    },

    inputs: content => {
      return `
    <div class="ModalSignUp-InputGroup">
      ${content.inputs
        .map(name => {
          if (name == 'branchAutocomplete') {
            return utils.inputTypes[name](content.branchPlaceholder)
          } else {
            return utils.inputTypes[name]()
          }
        })
        .join('')}
      <input style="display:none;" type="text" name="userType" value="${
        content.userType
      }"/>
    </div>
  `
    },

    BackButton: page => `
    <div class="ModalSignUp-back ${page}-back">${BackArrow(page)}</div>
  `,

    Header: backpage => `
    <div class=ModalSignUp-header>
      ${backpage ? utils.BackButton(backpage) : '<div></div>'}
    <div class="ModalSignUp-close">${svgClose}</div>
    </div>
  `,

    Heading: text => `
    <div>
      <h5 class="ModalSignUp-title">${text.title}</h5>
      ${
        text.subtitle
          ? `<p class="ModalSignUp-subtitle">${text.subtitle}</p>`
          : `<p></p>`
      }
    </div>
  `,

    Footer: (footerText, buttonId, buttonClass, buttonText) => `
    <div class="ModalSignUp-footer">
      ${
        footerText
          ? `<p class="ModalSignUp-footer-text">${footerText}</p>`
          : `<p></p>`
      }
      <button id="${buttonId}" class="ModalSignUp-button ${buttonClass}" type="button">${buttonText}</button>
    </div>
  `,

    //*************** page body utils */

    ArrowBody: cardArray => {
      let cards = cardArray.map(card =>
        utils.arrowCard(card.title, card.id, card.class, card.content)
      )
      return `<div class="u-push-3">${cards.join('')}</div>`
    },

    FormBody: (id, inputs, footer) => `
      <form name="Form" id="${id}" method="POST" class="ModalSignUp-form">
        ${inputs}
        ${footer}
      </form>
    `,

    //********************** create page utils */

    Page: (header, heading, body) => `
  <div class="ModalSignUp-wrapper">
    ${header}
    <div class="ModalSignUp-pageContainer">
      ${heading}
      ${body}
    </div>
  </div>
    `,

    TextPage: (header, title, text, footer) => `
  <div class="ModalSignUp-wrapper">
    ${header}
    <div class="ModalSignUp-pageContainer">
    <div>
      <h5 class="ModalSignUp-title">${title}</h5>
      <p class="ModalSignUp-text">${text}</p>
    </div>
      ${footer}
    </div>
  </div>
  `,

    spinner: () => `
  <div class="Spinner">
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
  <div class="Spinner-dot"></div>
</div>
  `,

    SpinnerPage: header => `
  <div class="ModalSignUp-wrapper">
    ${header}
    <div class="ModalSignUp-finish">
     ${utils.spinner()}
    </div>
    <div><div>
    <div><div>
  </div>
  `,

    SuccessPage: (header, title, text) => `
  <div class="ModalSignUp-wrapper">
    ${header}
    <div class="ModalSignUp-finish">
      <div class="pixel"></div>
      ${svgSuccess}
      <h3 class="u-push-3">${title}</h3>
      <p class="u-push-3" style="max-width: 250px; text-align:center;">${text}</p>
    </div>
  </div>
  `,

    FailPage: header => `
  <div class="ModalSignUp-wrapper">
    ${header}
    <div class="ModalSignUp-finish">
      ${svgFail}
      <h3 class="u-push-3">Something went wrong.</h3>
      <p class="u-push-3">Please try again later</p>
    </div>
  </div>
  `,

    createArrowPage: content =>
      utils.Page(
        utils.Header(content.backPage),
        utils.Heading(content.heading),
        utils.ArrowBody(content.arrows)
      ),
    createFormPage: (content, specialInputs = null) =>
      utils.Page(
        utils.Header(content.backPage),
        utils.Heading(content.heading),
        utils.FormBody(
          content.formId,
          specialInputs ? specialInputs : utils.inputs(content),
          utils.Footer(
            content.footerText,
            content.recId,
            content.buttonClass,
            content.buttonText
          )
        )
      ),
    tenantDetailsPage: content =>
      utils.Page(
        utils.Header(content.backPage),
        utils.Heading(content.heading),
        utils.FormBody(
          content.formId,
          utils.tenantDetailsSpecialInputs(),
          utils.Footer(
            content.footerText,
            content.recId,
            'Button-submit',
            'submit'
          )
        )
      ),
    createTextPage: content =>
      utils.TextPage(
        utils.Header(content.backPage),
        content.title,
        content.text,
        utils.Footer(null, content.recId, 'ModalSignUp-close', 'Okay!')
      ),

    createSuccessPage: (title, text) =>
      utils.SuccessPage(utils.Header(null), title, text),

    createView: (el, view, ga_string) => {
      el.empty().append(view)
      if ('ga' in window) {
        // eslint-disable-next-line
        ga('send', 'pageview', `virtualpage_${ga_string}`)
      }
    },

    hasClass: (el, classes) => el.indexOf(classes) !== -1,

    pages: {
      //arrow pages
      genericStart: () => utils.createArrowPage(content.START),

      landlordYesNo: () => utils.createArrowPage(content.LANDLORD_YES_NO),

      landlordUsesAgent: () =>
        utils.createArrowPage(content.LANDLORD_USES_AGENT),

      subscriberTenant: () => utils.createFormPage(content.SUBSCRIBER_TENANT),

      landlordSelfManage: () =>
        utils.createFormPage(content.LANDLORD_SELF_MANAGE),

      partner: () => utils.createTextPage(content.PARTNER),

      notPartner: () => utils.createFormPage(content.NOT_PARTNER),

      notSure: () => utils.createFormPage(content.NOT_SURE),

      //special flows:
      agent1: () => utils.createFormPage(content.AGENT1),

      agent2: () => utils.createFormPage(content.AGENT2, utils.agent2Inputs()),

      //after submit pages

      successPage: () =>
        utils.createSuccessPage(content.successTitle, content.successText),

      agentSuccessPage: () =>
        utils.createSuccessPage(
          content.agentSuccessTitle,
          content.agentSuccessText
        ),

      failPage: () => utils.FailPage(utils.Header(null)),

      spinnerPage: () => utils.SpinnerPage(utils.Header(null)),
    },

    // Return the UTM parameters that are relevant to the app.
    // If params are duplicated, the last value is used.
    getUTMs: () => {
      const utms = {}

      let query = window.location.search
      if (query.charAt(0) === '?') {
        query = query.slice(1)
      }

      if (!query) {
        return utms
      }

      const isRelevantUTMParam = param =>
        /utm_(campaign|medium|source)/i.test(param)
      const decode = value => decodeURIComponent(value.replace(/\+/g, ' '))
      const items = query.split('&')

      for (const item of items) {
        const parts = item.split('=')
        const param = decode(parts.shift())
        if (isRelevantUTMParam(param)) {
          utms[param] = decode(parts.join('='))
        }
      }

      return utms
    },

    insertLinkedInPixel: () => {
      let el = document.querySelector('.pixel')
      let pixel = `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1271810&conversionId=1220484&fmt=gif" />`
      el.innerHTML = pixel
    },
  }
})()

export default utils
